import { GetServerSidePropsContext } from 'next';

import Container from '~/core/ui/Container';
import Button from '~/core/ui/Button';
import Layout from '~/core/ui/Layout';
import SiteHeader from '~/components/SiteHeader';
import { withTranslationProps } from '~/lib/props/with-translation-props';
import Footer from '~/components/Footer';
import pipelineCode from '../../public/assets/images/code-pipeline.svg';
import background from '../../public/assets/images/background.svg';
import Image from 'next/image';
import { withAppProps } from '~/lib/props/with-app-props';
import configuration from '~/configuration';

function Index() {
  return (
    <Layout>
      <SiteHeader />
      <Image
        src={background}
        className="object-contain"
        height={1000}
        width={1000}
        alt={'background-flows'}
      ></Image>
      <Container>
        <div className="max-h-screen flex flex-col mt-[-55px]">
          <main className="flex container p-10  mx-auto px-4 items-center justify-between">
            {/* Left Content */}
            <div className="w-full md:w-1/2 pr-8 space-y-4">
              <div>
                <div className="space-y-4 flex items-center">
                  <h1 className="text-5xl font-bold leading-tight tracking-wide flex items-center">
                    Design. Automate. Optimize
                  </h1>
                </div>
              </div>
              <p className="leading-relaxed dark:text-gray-300 w-[90%]">
                Where AI, Humans, and Code Converge to Drive Operational Mastery. <br /> Achieve
                operational excellence with a platform designed for unified, efficient
                problem-solving.
              </p>
              <div className="flex space-x-4">
                <Button
                  size="custom"
                  className="px-6 py-3 rounded-full"
                  href="https://calendly.com/luciano-synergyshock/meeting"
                  target="_blank"
                >
                  Book a demo
                </Button>
              </div>
            </div>
            <div className="hidden md:block w-1/2">
              <Image
                src={pipelineCode}
                className="object-contain"
                height={1000}
                width={1000}
                alt={'pipelines-svg'}
              ></Image>
            </div>
          </main>
        </div>
      </Container>

      <Footer />
    </Layout>
  );
}

export default Index;

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const { locale } = ctx;

  const appProps = await withAppProps(ctx);

  if ('props' in appProps && appProps.props?.session) {
    return {
      redirect: {
        destination: configuration.paths.feed,
        permanent: false,
      },
    };
  }

  const { props } = await withTranslationProps({ locale });

  return {
    props,
  };
}
